@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.flex{
    display: flex;
    justify-content: space-between;
    min-height: 80px;
    max-height: 80px;
    box-shadow: 0 2px 4px 0 rgba(65,48,62,.15);
    padding-left: 20px;
    padding-right: 40px;
    align-items: center;
    position: fixed;
    top: 0;
    width: 97%;
    background-color: white;
    z-index: 1;
}
img{
    height: 100%;
    width: 100%;
}
.left{
    width: 20%;
}

.logo{
    height: 50px;
    width: 150px;
}
.center{
    width: 60%;
}
.right{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
.css-4ff9q7.Mui-active{
    color: #704c52 !important;
}
.css-1hv8oq8-MuiStepLabel-label{
    font-size: 11px !important;
    font-family: 'Poppins', sans-serif;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
    color: #704c52 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #704c52 !important;
}
/* .css-sghohy-MuiButtonBase-root-MuiButton-root{
    background-color: #704c52 !important;
    border-radius: 20px;
} */
.css-sghohy-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #513136 !important;
} 
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    color: #704c52 !important;
}
.center-body{
    background-color: #f5f4f5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
    position: relative;
    
}
.center-card{
    /* flex: 1; */
    overflow-y: auto; 
    min-height: 70vh;
    max-height: 70vh;
    width: 50%;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(65,48,62,.15);
    border-radius: 8px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.center-card::-webkit-scrollbar {
    display: none;

}
.stepper-btn-container{
    min-width: 50%;
    max-width: 50%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: white;
    min-height: 80px;
    max-height: 80px;
    box-shadow: 0 -2px 4px 0 rgba(0,0,0,.05);
    position: fixed;
    bottom: 0;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    

}
.quote-step{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    font-size: 20px;
    font-weight: bold;
}
.click{
    color: #704c52;
    font-weight: 500;
    cursor: pointer;
}
.category-flex{
    display: flex;
}
.modal-box{
    height: 250px;
    width: 450px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    border: none !important;

}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
    z-index: 999; /* Ensure it's above other content */
  }
.close-icon{
    color: #704c52;
    
}
.close-icon:hover{
    color:red;

}
.modal-header{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
}
.modal-body{
    padding: 0px 20px;
}
.modal-description{
    font-size: 13px;
    text-align: left;
}

/* .radio-cards{
    border: 2px solid black;
} */

.css-46bh2p-MuiCardContent-root:last-child{
    padding-bottom: 0px !important;
    width: 300px !important;
} 

.category-flex{
    display: flex;
}

@media screen and (max-width: 769px) {
    .center-card {
      width: 90%;
    }
  }
@media screen and (max-width: 460px) {
    .flex{
        gap: 20px !important;
    }
    .left{
        width: 20% !important;
    }
    .center{
        width: 70%;
    }
    .right{
        width: 10%;
    }
    .right>p{
        display: none !important;
    }
    .logo img {
        content: url('../../assets/venuslogo.svg');
        height: 50px !important;
        width: 50px !important;
        object-fit: cover;
       
      }
    .radio-group{
        flex-direction: column;
        gap: 20px;
        margin: 20px !important;
    }
    .radioCard{
        min-width: 300px !important;
        max-width: 300px !important;
        padding: 20px !important;
        margin: 10px 40px !important;
        
    }
    .drop-down-container{
        flex-direction: column;
        width: 90%;
        align-items: center;
        margin-top: 30px !important;
    }
   
    .css-a6kcc0-MuiFormGroup-root{
        gap: 15px !important;
        justify-content: center;
        
    }
    .stepper-btn-container {
        min-width: 90% !important;
        max-width: 90% !important;

    }
    .modal-box{
        
        height: auto !important;
        width: 300px !important;
    }
    
    .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel{
        font-size: 10px !important;
        margin-top: 8px !important;
    }
    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
        width: 60% !important;
    }
    .right{
        margin-right: 10px !important;
    }
    .drop-down {
        width: 100% !important;
    }
    .form-input {
        width: 100% !important;
    }
    .css-2fdkz6.MuiStepLabel-alternativeLabel {
        font-size: 10px !important;
    }
    .css-vmqna8-MuiFormGroup-root{
        gap: 20px !important;
    }
  
    
  }